
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './store';

        export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type JsonLocation = components['schemas']['JsonLocation'];
                    export type ParcelShopSearchOrigin = components['schemas']['ParcelShopSearchOrigin'];
                    export type AddressCleansingSuggestion = components['schemas']['AddressCleansingSuggestion'];
                    export type AddressCleansingSuggestionMaybe = components['schemas']['AddressCleansingSuggestionMaybe'];
                    export type ParcelShopSearchData = components['schemas']['ParcelShopSearchData'];
                    export type ProductStockStatusType = components['schemas']['ProductStockStatusType'];
                    export type OpeningHourTexts = components['schemas']['OpeningHourTexts'];
                    export type ParcelShopRenderType = components['schemas']['ParcelShopRenderType'];
                    export type StoreParcelShopResult = components['schemas']['StoreParcelShopResult'];
                    export type FindStoreResult = components['schemas']['FindStoreResult'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ProductNameLineType = components['schemas']['ProductNameLineType'];
                    export type ProductNameLine = components['schemas']['ProductNameLine'];
                    export type ProductName = components['schemas']['ProductName'];
                    export type ProductInfoModel = components['schemas']['ProductInfoModel'];
                    export type ProductStoreStockRequest = components['schemas']['ProductStoreStockRequest'];
                    export type ProductStockCheckMarkType = components['schemas']['ProductStockCheckMarkType'];
                    export type ProductStoreStockViewModel = components['schemas']['ProductStoreStockViewModel'];
                    

        export function SwaggerOperationResultredirectLinkActionDiscriminator<T>(
    model: components['schemas']['SwaggerOperationResult']['redirectLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function ProductStoreStockViewModelfindProductInStoreLinkActionDiscriminator<T>(
    model: components['schemas']['ProductStoreStockViewModel']['findProductInStoreLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}

        
        

        export type SearchStoreByAddressParams = paths['/internal/Store/SearchStoreByAddress']['get']['parameters']['query'];

        export type SearchStoreByAddressSuccess = paths['/internal/Store/SearchStoreByAddress']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByAddressUrl = '/internal/Store/SearchStoreByAddress';

        
        export function SearchStoreByAddress(params: SearchStoreByAddressParams): Promise<SearchStoreByAddressSuccess> {
            return fetchJSON<SearchStoreByAddressSuccess>(`/internal/Store/SearchStoreByAddress${formatParams(params)}`)
        }
    


    
        

        export type SearchStoreByCoordinatesParams = paths['/internal/Store/SearchStoreByCoordinates']['get']['parameters']['query'];

        export type SearchStoreByCoordinatesSuccess = paths['/internal/Store/SearchStoreByCoordinates']['get']['responses']['200']['content']['text/plain'];

        export const SearchStoreByCoordinatesUrl = '/internal/Store/SearchStoreByCoordinates';

        
        export function SearchStoreByCoordinates(params: SearchStoreByCoordinatesParams): Promise<SearchStoreByCoordinatesSuccess> {
            return fetchJSON<SearchStoreByCoordinatesSuccess>(`/internal/Store/SearchStoreByCoordinates${formatParams(params)}`)
        }
    


    
        

        export type ValidateParams = paths['/internal/Store/Validate']['post']['parameters']['query'];

        export type ValidateSuccess = paths['/internal/Store/Validate']['post']['responses']['200']['content']['text/plain'];

        export const ValidateUrl = '/internal/Store/Validate';

        
        export function Validate(params: ValidateParams): Promise<ValidateSuccess> {
            return postQuery<ValidateSuccess>(`/internal/Store/Validate${formatParams(params)}`)
        }
    


    
        

        export type _GetSelectedProductParams = paths['/internal/Store/_GetSelectedProduct']['get']['parameters']['query'];

        export type _GetSelectedProductSuccess = paths['/internal/Store/_GetSelectedProduct']['get']['responses']['200']['content']['text/plain'];

        export const _GetSelectedProductUrl = '/internal/Store/_GetSelectedProduct';

        
        export function _GetSelectedProduct(params: _GetSelectedProductParams): Promise<_GetSelectedProductSuccess> {
            return fetchJSON<_GetSelectedProductSuccess>(`/internal/Store/_GetSelectedProduct${formatParams(params)}`)
        }
    


    
        

        export type SetUserPreferredStoreParams = paths['/internal/Store/SetUserPreferredStore']['post']['parameters']['query'];

        export type SetUserPreferredStoreSuccess = paths['/internal/Store/SetUserPreferredStore']['post']['responses']['200']['content']['text/plain'];

        export const SetUserPreferredStoreUrl = '/internal/Store/SetUserPreferredStore';

        
        export function SetUserPreferredStore(params: SetUserPreferredStoreParams): Promise<SetUserPreferredStoreSuccess> {
            return postQuery<SetUserPreferredStoreSuccess>(`/internal/Store/SetUserPreferredStore${formatParams(params)}`)
        }
    


    
        

        export type GetProductStoreStockModelParams = paths['/internal/Store/GetProductStoreStockModel']['get']['parameters']['query'];

        export type GetProductStoreStockModelSuccess = paths['/internal/Store/GetProductStoreStockModel']['get']['responses']['200']['content']['text/plain'];

        export const GetProductStoreStockModelUrl = '/internal/Store/GetProductStoreStockModel';

        
        export function GetProductStoreStockModel(params: GetProductStoreStockModelParams): Promise<GetProductStoreStockModelSuccess> {
            return fetchJSON<GetProductStoreStockModelSuccess>(`/internal/Store/GetProductStoreStockModel${formatParams(params)}`)
        }
    


    
    