import { type LinkActionType, LinkActionTypeactionDiscriminator } from 'autogen/swagger/page';
import { type ComponentPropsWithoutRef, forwardRef } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

export type LinkAction = LinkActionType['action'];

export type LinkActionProps = Omit<LinkProps, 'to' | 'state' | '$$typeof'> & {
    action: LinkAction;
} & ComponentPropsWithoutRef<'a'>;

const DefaultLink = forwardRef<HTMLAnchorElement, LinkActionProps>(({ action, children, ...restProps }, ref) => (
    <Link ref={ref} to={action.url} state={action} reloadDocument {...restProps}>
        {children}
    </Link>
));

const LinkAction = forwardRef<HTMLAnchorElement, LinkActionProps>(({ action, ...restProps }, ref) =>
    LinkActionTypeactionDiscriminator(
        action,
        (advisorLinkAction) => <DefaultLink action={advisorLinkAction} ref={ref} {...restProps} />,
        (articleSectionLinkAction) => <DefaultLink action={articleSectionLinkAction} ref={ref} {...restProps} />,
        (articleTagLinkAction) => <DefaultLink action={articleTagLinkAction} ref={ref} {...restProps} />,
        (brandClubLinkAction) => <DefaultLink action={brandClubLinkAction} ref={ref} {...restProps} />,
        (brandLinkAction) => <DefaultLink action={brandLinkAction} ref={ref} {...restProps} />,
        (campaignOfferLinkAction) => <DefaultLink action={campaignOfferLinkAction} ref={ref} {...restProps} />,
        (cmsMenuItemLinkAction) => <DefaultLink action={cmsMenuItemLinkAction} ref={ref} {...restProps} />,
        (cmsPageLinkAction) => <DefaultLink action={cmsPageLinkAction} ref={ref} {...restProps} />,
        (discountLinkAction) => <DefaultLink action={discountLinkAction} ref={ref} {...restProps} />,
        (externalLinkAction) => <DefaultLink action={externalLinkAction} ref={ref} {...restProps} />,
        (frontpageLinkAction) => <DefaultLink action={frontpageLinkAction} ref={ref} {...restProps} />,
        (plainLinkAction) => <DefaultLink action={plainLinkAction} ref={ref} {...restProps} />,
        (productGroupLinkAction) => <DefaultLink action={productGroupLinkAction} ref={ref} {...restProps} />,
        (productLinkAction) => <DefaultLink action={productLinkAction} ref={ref} {...restProps} />,
        (seriesLinkAction) => <DefaultLink action={seriesLinkAction} ref={ref} {...restProps} />,
        (staticPageLinkAction) => <DefaultLink action={staticPageLinkAction} ref={ref} {...restProps} />,
        (storeLinkAction) => <DefaultLink action={storeLinkAction} ref={ref} {...restProps} />,
        () => {
            console.log(action);

            throw new Error('Invalid Link Action');
        }
    )
);

export default LinkAction;
