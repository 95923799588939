
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {SetZipCode,SetZipCodeSuccess,SetZipCodeParams,SetZipCodeByCoordinates,SetZipCodeByCoordinatesSuccess,SetZipCodeByCoordinatesParams,GetExpectedDeliverySummaryForBasket,GetExpectedDeliverySummaryForBasketSuccess,GetExpectedDeliverySummaryForBasketParams,GetExpectedDeliverySummaryForProductPage,GetExpectedDeliverySummaryForProductPageSuccess,GetExpectedDeliverySummaryForProductPageParams,GetExpectedDeliveryFullInformation,GetExpectedDeliveryFullInformationSuccess,GetExpectedDeliveryFullInformationParams,GetExpectedDeliveryInformationForProductPage,GetExpectedDeliveryInformationForProductPageSuccess,GetExpectedDeliveryInformationForProductPageParams,GetExpectedDeliveryInformationForBasket,GetExpectedDeliveryInformationForBasketSuccess} from './index'

        
        
      export function useSetZipCodeMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetZipCodeSuccess, TError, {params: SetZipCodeParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) =>
    SetZipCode(params, ), options)}
  
    
        
      export function useSetZipCodeByCoordinatesMutation<TError = unknown, TVariables = unknown>(options?: Omit<UseMutationOptions<SetZipCodeByCoordinatesSuccess, TError, {params: SetZipCodeByCoordinatesParams} & TVariables>, 'mutationFn'>) {
          return useMutation(({params, }) =>
    SetZipCodeByCoordinates(params, ), options)}
  
    
        
    export const GetExpectedDeliverySummaryForBasketQueryKey = 'expecteddelivery_GetExpectedDeliverySummaryForBasket';

    export function useGetExpectedDeliverySummaryForBasketQuery<TError = unknown>(params: GetExpectedDeliverySummaryForBasketParams, options?: Omit<UseQueryOptions<GetExpectedDeliverySummaryForBasketSuccess, TError, GetExpectedDeliverySummaryForBasketSuccess, [string, GetExpectedDeliverySummaryForBasketParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliverySummaryForBasketSuccess | (() => GetExpectedDeliverySummaryForBasketSuccess);},  baseQueryKey = GetExpectedDeliverySummaryForBasketQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliverySummaryForBasket(params), options);
        }
  
    
        
    export const GetExpectedDeliverySummaryForProductPageQueryKey = 'expecteddelivery_GetExpectedDeliverySummaryForProductPage';

    export function useGetExpectedDeliverySummaryForProductPageQuery<TError = unknown>(params: GetExpectedDeliverySummaryForProductPageParams, options?: Omit<UseQueryOptions<GetExpectedDeliverySummaryForProductPageSuccess, TError, GetExpectedDeliverySummaryForProductPageSuccess, [string, GetExpectedDeliverySummaryForProductPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliverySummaryForProductPageSuccess | (() => GetExpectedDeliverySummaryForProductPageSuccess);},  baseQueryKey = GetExpectedDeliverySummaryForProductPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliverySummaryForProductPage(params), options);
        }
  
    
        
    export const GetExpectedDeliveryFullInformationQueryKey = 'expecteddelivery_GetExpectedDeliveryFullInformation';

    export function useGetExpectedDeliveryFullInformationQuery<TError = unknown>(params: GetExpectedDeliveryFullInformationParams, options?: Omit<UseQueryOptions<GetExpectedDeliveryFullInformationSuccess, TError, GetExpectedDeliveryFullInformationSuccess, [string, GetExpectedDeliveryFullInformationParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryFullInformationSuccess | (() => GetExpectedDeliveryFullInformationSuccess);},  baseQueryKey = GetExpectedDeliveryFullInformationQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliveryFullInformation(params), options);
        }
  
    
        
    export const GetExpectedDeliveryInformationForProductPageQueryKey = 'expecteddelivery_GetExpectedDeliveryInformationForProductPage';

    export function useGetExpectedDeliveryInformationForProductPageQuery<TError = unknown>(params: GetExpectedDeliveryInformationForProductPageParams, options?: Omit<UseQueryOptions<GetExpectedDeliveryInformationForProductPageSuccess, TError, GetExpectedDeliveryInformationForProductPageSuccess, [string, GetExpectedDeliveryInformationForProductPageParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryInformationForProductPageSuccess | (() => GetExpectedDeliveryInformationForProductPageSuccess);},  baseQueryKey = GetExpectedDeliveryInformationForProductPageQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => GetExpectedDeliveryInformationForProductPage(params), options);
        }
  
    
        
    export const GetExpectedDeliveryInformationForBasketQueryKey = 'expecteddelivery_GetExpectedDeliveryInformationForBasket';

    export function useGetExpectedDeliveryInformationForBasketQuery<TError = unknown>( options?: Omit<UseQueryOptions<GetExpectedDeliveryInformationForBasketSuccess, TError, GetExpectedDeliveryInformationForBasketSuccess, [string, ]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: GetExpectedDeliveryInformationForBasketSuccess | (() => GetExpectedDeliveryInformationForBasketSuccess);},  baseQueryKey = GetExpectedDeliveryInformationForBasketQueryKey) {
            return useQuery([baseQueryKey, ],
            GetExpectedDeliveryInformationForBasket, options);
        }
  
    
    