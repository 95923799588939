
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './expecteddelivery';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type ZipCodeSource = components['schemas']['ZipCodeSource'];
                    export type ZipCodeWithSourceViewModel = components['schemas']['ZipCodeWithSourceViewModel'];
                    export type ZipCodeWithSourceViewModelMaybe = components['schemas']['ZipCodeWithSourceViewModelMaybe'];
                    export type DeliveryCountdownModel = components['schemas']['DeliveryCountdownModel'];
                    export type DeliveryCountdownModelMaybe = components['schemas']['DeliveryCountdownModelMaybe'];
                    export type ExpectedDeliverySingleSupplierModel = components['schemas']['ExpectedDeliverySingleSupplierModel'];
                    export type ExpectedDeliverySingleSupplierModelMaybe = components['schemas']['ExpectedDeliverySingleSupplierModelMaybe'];
                    export type ExpectedDeliverySummaryViewModel = components['schemas']['ExpectedDeliverySummaryViewModel'];
                    export type ZipCodeWithSource = components['schemas']['ZipCodeWithSource'];
                    export type ExpectedDeliveryType = components['schemas']['ExpectedDeliveryType'];
                    export type ExpectedDeliverySingleMethodModel = components['schemas']['ExpectedDeliverySingleMethodModel'];
                    export type ExpectedDeliveryFullInformationModel = components['schemas']['ExpectedDeliveryFullInformationModel'];
                    export type CompanyAddress = components['schemas']['CompanyAddress'];
                    export type ParcelShop = components['schemas']['ParcelShop'];
                    export type PrivateAddress = components['schemas']['PrivateAddress'];
                    export type DeliveryType = components['schemas']['DeliveryType'];
                    export type DeliveryMethodTagType = components['schemas']['DeliveryMethodTagType'];
                    export type DeliveryMethodTag = components['schemas']['DeliveryMethodTag'];
                    export type DeliveryMethodTagMaybe = components['schemas']['DeliveryMethodTagMaybe'];
                    export type DeliveryPrice = components['schemas']['DeliveryPrice'];
                    export type ParcelShopPrice = components['schemas']['ParcelShopPrice'];
                    export type SubscriptionDeliveryPrice = components['schemas']['SubscriptionDeliveryPrice'];
                    export type ShippingSupplier = components['schemas']['ShippingSupplier'];
                    export type UpsaleDeliveryMethod = components['schemas']['UpsaleDeliveryMethod'];
                    export type DeliveryMethod = components['schemas']['DeliveryMethod'];
                    export type SelectedDeliveryMethod = components['schemas']['SelectedDeliveryMethod'];
                    export type DeliveryMethodCategory = components['schemas']['DeliveryMethodCategory'];
                    export type SimpleDeliveryGroup = components['schemas']['SimpleDeliveryGroup'];
                    export type DropShipDisclaimer = components['schemas']['DropShipDisclaimer'];
                    export type DropShipDisclaimerMaybe = components['schemas']['DropShipDisclaimerMaybe'];
                    export type ExpectedDeliveryInformation = components['schemas']['ExpectedDeliveryInformation'];
                    

        export function SwaggerOperationResultredirectLinkActionDiscriminator<T>(
    model: components['schemas']['SwaggerOperationResult']['redirectLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function UpsaleDeliveryMethoddestinationDiscriminator<T>(
    model: components['schemas']['UpsaleDeliveryMethod']['destination'],
    funcCompanyAddress: (m: components['schemas']['CompanyAddress']) => T,
    funcParcelShop: (m: components['schemas']['ParcelShop']) => T,
    funcPrivateAddress: (m: components['schemas']['PrivateAddress']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CompanyAddress': return funcCompanyAddress(model as components['schemas']['CompanyAddress']);
        case 'ParcelShop': return funcParcelShop(model as components['schemas']['ParcelShop']);
        case 'PrivateAddress': return funcPrivateAddress(model as components['schemas']['PrivateAddress']);

        default:
            return defaultFunc();
    }
}export function UpsaleDeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['UpsaleDeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function DeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['DeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function SelectedDeliveryMethoddestinationDiscriminator<T>(
    model: components['schemas']['SelectedDeliveryMethod']['destination'],
    funcCompanyAddress: (m: components['schemas']['CompanyAddress']) => T,
    funcParcelShop: (m: components['schemas']['ParcelShop']) => T,
    funcPrivateAddress: (m: components['schemas']['PrivateAddress']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CompanyAddress': return funcCompanyAddress(model as components['schemas']['CompanyAddress']);
        case 'ParcelShop': return funcParcelShop(model as components['schemas']['ParcelShop']);
        case 'PrivateAddress': return funcPrivateAddress(model as components['schemas']['PrivateAddress']);

        default:
            return defaultFunc();
    }
}export function SelectedDeliveryMethodpriceDiscriminator<T>(
    model: components['schemas']['SelectedDeliveryMethod']['price'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function DeliveryMethodCategorydeliveryPriceDiscriminator<T>(
    model: components['schemas']['DeliveryMethodCategory']['deliveryPrice'],
    funcDeliveryPrice: (m: components['schemas']['DeliveryPrice']) => T,
    funcParcelShopPrice: (m: components['schemas']['ParcelShopPrice']) => T,
    funcSubscriptionDeliveryPrice: (m: components['schemas']['SubscriptionDeliveryPrice']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryPrice': return funcDeliveryPrice(model as components['schemas']['DeliveryPrice']);
        case 'ParcelShopPrice': return funcParcelShopPrice(model as components['schemas']['ParcelShopPrice']);
        case 'SubscriptionDeliveryPrice': return funcSubscriptionDeliveryPrice(model as components['schemas']['SubscriptionDeliveryPrice']);

        default:
            return defaultFunc();
    }
}export function SimpleDeliveryGrouphighlightedDeliveryMethodsDiscriminator<T>(
    model: components['schemas']['SimpleDeliveryGroup']['highlightedDeliveryMethods'][0],
    funcUpsaleDeliveryMethod: (m: components['schemas']['UpsaleDeliveryMethod']) => T,
    funcDeliveryMethod: (m: components['schemas']['DeliveryMethod']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'UpsaleDeliveryMethod': return funcUpsaleDeliveryMethod(model as components['schemas']['UpsaleDeliveryMethod']);
        case 'DeliveryMethod': return funcDeliveryMethod(model as components['schemas']['DeliveryMethod']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}export function SimpleDeliveryGroupdeliveryMethodCategoriesDiscriminator<T>(
    model: components['schemas']['SimpleDeliveryGroup']['deliveryMethodCategories'][0],
    funcDeliveryMethodCategory: (m: components['schemas']['DeliveryMethodCategory']) => T,
    funcSelectedDeliveryMethod: (m: components['schemas']['SelectedDeliveryMethod']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'DeliveryMethodCategory': return funcDeliveryMethodCategory(model as components['schemas']['DeliveryMethodCategory']);
        case 'SelectedDeliveryMethod': return funcSelectedDeliveryMethod(model as components['schemas']['SelectedDeliveryMethod']);

        default:
            return defaultFunc();
    }
}

        
        

        export type SetZipCodeParams = paths['/internal/ExpectedDelivery/SetZipCode']['post']['parameters']['query'];

        export type SetZipCodeSuccess = paths['/internal/ExpectedDelivery/SetZipCode']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeUrl = '/internal/ExpectedDelivery/SetZipCode';

        
        export function SetZipCode(params: SetZipCodeParams): Promise<SetZipCodeSuccess> {
            return postQuery<SetZipCodeSuccess>(`/internal/ExpectedDelivery/SetZipCode${formatParams(params)}`)
        }
    


    
        

        export type SetZipCodeByCoordinatesParams = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['parameters']['query'];

        export type SetZipCodeByCoordinatesSuccess = paths['/internal/ExpectedDelivery/SetZipCodeByCoordinates']['post']['responses']['200']['content']['text/plain'];

        export const SetZipCodeByCoordinatesUrl = '/internal/ExpectedDelivery/SetZipCodeByCoordinates';

        
        export function SetZipCodeByCoordinates(params: SetZipCodeByCoordinatesParams): Promise<SetZipCodeByCoordinatesSuccess> {
            return postQuery<SetZipCodeByCoordinatesSuccess>(`/internal/ExpectedDelivery/SetZipCodeByCoordinates${formatParams(params)}`)
        }
    


    
        

        export type GetExpectedDeliverySummaryForBasketParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket';

        
        export function GetExpectedDeliverySummaryForBasket(params: GetExpectedDeliverySummaryForBasketParams): Promise<GetExpectedDeliverySummaryForBasketSuccess> {
            return fetchJSON<GetExpectedDeliverySummaryForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForBasket${formatParams(params)}`)
        }
    


    
        

        export type GetExpectedDeliverySummaryForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliverySummaryForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliverySummaryForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage';

        
        export function GetExpectedDeliverySummaryForProductPage(params: GetExpectedDeliverySummaryForProductPageParams): Promise<GetExpectedDeliverySummaryForProductPageSuccess> {
            return fetchJSON<GetExpectedDeliverySummaryForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliverySummaryForProductPage${formatParams(params)}`)
        }
    


    
        

        export type GetExpectedDeliveryFullInformationParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['parameters']['query'];

        export type GetExpectedDeliveryFullInformationSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryFullInformationUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation';

        
        export function GetExpectedDeliveryFullInformation(params: GetExpectedDeliveryFullInformationParams): Promise<GetExpectedDeliveryFullInformationSuccess> {
            return fetchJSON<GetExpectedDeliveryFullInformationSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryFullInformation${formatParams(params)}`)
        }
    


    
        

        export type GetExpectedDeliveryInformationForProductPageParams = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['parameters']['query'];

        export type GetExpectedDeliveryInformationForProductPageSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForProductPageUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage';

        
        export function GetExpectedDeliveryInformationForProductPage(params: GetExpectedDeliveryInformationForProductPageParams): Promise<GetExpectedDeliveryInformationForProductPageSuccess> {
            return fetchJSON<GetExpectedDeliveryInformationForProductPageSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForProductPage${formatParams(params)}`)
        }
    


    
        

        

        export type GetExpectedDeliveryInformationForBasketSuccess = paths['/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket']['get']['responses']['200']['content']['text/plain'];

        export const GetExpectedDeliveryInformationForBasketUrl = '/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket';

        
        export function GetExpectedDeliveryInformationForBasket(): Promise<GetExpectedDeliveryInformationForBasketSuccess> {
            return fetchJSON<GetExpectedDeliveryInformationForBasketSuccess>(`/internal/ExpectedDelivery/GetExpectedDeliveryInformationForBasket`)
        }
    


    
    