
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './progressivediscount';

        export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type ProgressiveDiscountViewModel = components['schemas']['ProgressiveDiscountViewModel'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type ProgressiveDiscountStepViewModel = components['schemas']['ProgressiveDiscountStepViewModel'];
                    export type ProgressiveDiscountDetailsViewModel = components['schemas']['ProgressiveDiscountDetailsViewModel'];
                    

        export function ProgressiveDiscountViewModeldetailsLinkActionDiscriminator<T>(
    model: components['schemas']['ProgressiveDiscountViewModel']['detailsLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}

        
        

        export type ProgressiveDiscountForProductParams = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountForProductSuccess = paths['/internal/Discount/ProgressiveDiscountForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountForProductUrl = '/internal/Discount/ProgressiveDiscountForProduct';

        
        export function ProgressiveDiscountForProduct(params: ProgressiveDiscountForProductParams): Promise<ProgressiveDiscountForProductSuccess> {
            return fetchJSON<ProgressiveDiscountForProductSuccess>(`/internal/Discount/ProgressiveDiscountForProduct${formatParams(params)}`)
        }
    


    
        

        export type ProgressiveDiscountDetailsForProductParams = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['parameters']['query'];

        export type ProgressiveDiscountDetailsForProductSuccess = paths['/internal/Discount/ProgressiveDiscountDetailsForProduct']['get']['responses']['200']['content']['text/plain'];

        export const ProgressiveDiscountDetailsForProductUrl = '/internal/Discount/ProgressiveDiscountDetailsForProduct';

        
        export function ProgressiveDiscountDetailsForProduct(params: ProgressiveDiscountDetailsForProductParams): Promise<ProgressiveDiscountDetailsForProductSuccess> {
            return fetchJSON<ProgressiveDiscountDetailsForProductSuccess>(`/internal/Discount/ProgressiveDiscountDetailsForProduct${formatParams(params)}`)
        }
    


    
    