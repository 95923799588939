import { MenuItemMyProfileViewModel } from 'autogen/swagger/myprofile';
import MenuWithHeader from 'components/sideMenu/components/Menu/components/MenuWithHeader';
import styled from 'styled-components';
import format from 'helpers/translations';
import useIsClubMatasMember from 'hooks/user/useIsClubMatasMember';
import { ColorClubMatasPlus1, ColorClubMatas1, ColorBeauty2 } from 'autogen/design-tokens/tokens';
import useSmartviewManager from 'components/generic/smartview/shared/hooks/useSmartviewManager';
import dynamic from 'helpers/dynamic';
import DefaultMenuItem from './DefaultMenuItem';
import { DefaultMenuItemProps } from '../types';

type MyProfileMenuItemProps = {
    model: MenuItemMyProfileViewModel;
    defaultProps: DefaultMenuItemProps;
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ClubMatasColor = styled.span<{ isClubMatasPlus: boolean }>`
    font-weight: bold;
    color: ${({ isClubMatasPlus }) => (isClubMatasPlus ? ColorClubMatasPlus1 : ColorClubMatas1)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    max-width: 112px;
    line-height: normal;
`;

const Text = styled.span`
    white-space: nowrap;
`;

const Checkmark = styled(dynamic(() => import('icons/Checkmark.svg?react')))`
    fill: ${ColorBeauty2};
`;

export default function MyProfileMenuItem({ model, defaultProps, ...restProps }: MyProfileMenuItemProps) {
    const { text, subText, isSelected, linkAction, target, isExpanded, isChecked, subMenu } = model;
    const { iconUrl, styling, id } = defaultProps;
    const { isMatasPlusMember } = useIsClubMatasMember();

    if (subMenu.length) {
        return <MyProfileSubMenuItem model={model} defaultProps={defaultProps} />;
    }

    const checkmark = !isExpanded && isChecked && isSelected && <Checkmark />;

    return (
        <DefaultMenuItem.Anchor
            anchorProps={{ action: linkAction, target }}
            selected={isSelected}
            styling={styling}
            iconUrl={iconUrl}
            beforeSlot={checkmark || undefined}
            {...restProps}
        >
            <Wrapper>
                <Text> {isSelected ? <b>{text}</b> : text}</Text>
                <ClubMatasColor isClubMatasPlus={isMatasPlusMember}> {format(subText?.text)}</ClubMatasColor>
            </Wrapper>
        </DefaultMenuItem.Anchor>
    );
}

const ArrowRight = dynamic(() => import('icons/ArrowRight.svg?react'), { ssr: false });

function MyProfileSubMenuItem({
    model: { text, subText, isSelected, loginLink, target, canHaveArrowIcon, staticPageType, isExpanded, subMenu },
    defaultProps: { iconUrl, styling, id },
    ...restProps
}: MyProfileMenuItemProps) {
    const smartSideDrawerId = `${subMenu[0].id}-myprofile-menu`;
    const { open: openSubMenu, close: closeSubMenu } = useSmartviewManager(smartSideDrawerId);

    return (
        <>
            <DefaultMenuItem.Button
                buttonProps={{ onClick: openSubMenu }}
                afterSlot={<ArrowRight width={16} height={16} />}
                selected={isSelected}
                styling={styling}
                iconUrl={iconUrl}
                {...restProps}
            >
                {text}
            </DefaultMenuItem.Button>

            <MenuWithHeader
                menu={subMenu[0]}
                smartSideDrawerId={smartSideDrawerId}
                backButtonCallback={closeSubMenu}
                animate={false}
            />
        </>
    );
}
