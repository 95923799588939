import { getPageActionModelDiscriminator } from 'autogen/swagger/page';
import { ComponentPropsWithRef, ComponentPropsWithoutRef, ForwardedRef, forwardRef, memo } from 'react';
import { operations } from 'autogen/swagger/page/page';
import LinkAction from './components/LinkAction/LinkAction';
import CustomAction from './components/CustomAction/CustomAction';

export type PageActionModel = operations['PageActionModel']['responses']['200']['content']['application/json'];

export type PageActionProps = {
    action: PageActionModel;
} & ComponentPropsWithoutRef<'a'> &
    ComponentPropsWithoutRef<'button'>;

const PageAction = forwardRef<HTMLAnchorElement | HTMLButtonElement, PageActionProps>((props, ref) => {
    const { action, ...restProps } = props;

    return getPageActionModelDiscriminator(
        action,
        (customPageAction) => (
            <CustomAction
                action={customPageAction.action}
                ref={ref as ForwardedRef<HTMLButtonElement>}
                {...restProps}
            />
        ),
        (linkAction) => (
            <LinkAction action={linkAction.action} ref={ref as ForwardedRef<HTMLAnchorElement>} {...restProps} />
        ),
        () => null
    );
});

export default memo(PageAction);
