
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './liveevent';

        export type LiveEventFilterType = components['schemas']['LiveEventFilterType'];
                    export type ILiveEventFilterViewModel = components['schemas']['ILiveEventFilterViewModel'];
                    export type QueryStringParameter = components['schemas']['QueryStringParameter'];
                    export type LiveEventFilterViewModel = components['schemas']['LiveEventFilterViewModel'];
                    export type ResponsiveImage = components['schemas']['ResponsiveImage'];
                    export type ResponsiveImageBreakPoint = components['schemas']['ResponsiveImageBreakPoint'];
                    export type ResponsiveImageSource = components['schemas']['ResponsiveImageSource'];
                    export type ResponsiveImageModel = components['schemas']['ResponsiveImageModel'];
                    export type ResponsiveImageModelMaybe = components['schemas']['ResponsiveImageModelMaybe'];
                    export type CertificationCustomAction = components['schemas']['CertificationCustomAction'];
                    export type JavascriptHookType = components['schemas']['JavascriptHookType'];
                    export type JavascriptHookCustomAction = components['schemas']['JavascriptHookCustomAction'];
                    export type LiveEventCustomAction = components['schemas']['LiveEventCustomAction'];
                    export type SurveyCustomAction = components['schemas']['SurveyCustomAction'];
                    export type VimeoCustomAction = components['schemas']['VimeoCustomAction'];
                    export type CustomActionType = components['schemas']['CustomActionType'];
                    export type AdvisorLinkAction = components['schemas']['AdvisorLinkAction'];
                    export type ArticleSectionLinkAction = components['schemas']['ArticleSectionLinkAction'];
                    export type ArticleTagLinkAction = components['schemas']['ArticleTagLinkAction'];
                    export type BrandClubLinkAction = components['schemas']['BrandClubLinkAction'];
                    export type BrandLinkAction = components['schemas']['BrandLinkAction'];
                    export type CampaignOfferId = components['schemas']['CampaignOfferId'];
                    export type CampaignOfferLinkAction = components['schemas']['CampaignOfferLinkAction'];
                    export type CmsMenuItemLinkAction = components['schemas']['CmsMenuItemLinkAction'];
                    export type CmsPageLinkAction = components['schemas']['CmsPageLinkAction'];
                    export type DiscountLinkAction = components['schemas']['DiscountLinkAction'];
                    export type ExternalLinkAction = components['schemas']['ExternalLinkAction'];
                    export type FrontpageLinkAction = components['schemas']['FrontpageLinkAction'];
                    export type PlainLinkAction = components['schemas']['PlainLinkAction'];
                    export type ProductGroupLinkAction = components['schemas']['ProductGroupLinkAction'];
                    export type ProductLinkAction = components['schemas']['ProductLinkAction'];
                    export type SeriesLinkAction = components['schemas']['SeriesLinkAction'];
                    export type StaticPageType = components['schemas']['StaticPageType'];
                    export type StaticPageLinkAction = components['schemas']['StaticPageLinkAction'];
                    export type StoreLinkAction = components['schemas']['StoreLinkAction'];
                    export type LinkActionType = components['schemas']['LinkActionType'];
                    export type LinkActionMaybe = components['schemas']['LinkActionMaybe'];
                    export type LiveEventBlockViewModel = components['schemas']['LiveEventBlockViewModel'];
                    export type SubscribeToNotificationsBlockViewModel = components['schemas']['SubscribeToNotificationsBlockViewModel'];
                    export type LiveEventSliderViewModel = components['schemas']['LiveEventSliderViewModel'];
                    export type LiveEventSliderViewModelMaybe = components['schemas']['LiveEventSliderViewModelMaybe'];
                    export type LiveEventFilteredResultViewModel = components['schemas']['LiveEventFilteredResultViewModel'];
                    export type LiveEventFilteredResultViewModelMaybe = components['schemas']['LiveEventFilteredResultViewModelMaybe'];
                    export type SortDirection = components['schemas']['SortDirection'];
                    export type LiveEventListUnsubscribeViewModel = components['schemas']['LiveEventListUnsubscribeViewModel'];
                    export type LiveEventListUnsubscribeViewModelMaybe = components['schemas']['LiveEventListUnsubscribeViewModelMaybe'];
                    export type LiveEventListViewModel = components['schemas']['LiveEventListViewModel'];
                    export type InspirationMatasLivePageViewModel = components['schemas']['InspirationMatasLivePageViewModel'];
                    export type ValidationResult = components['schemas']['ValidationResult'];
                    export type SwaggerOperationResult = components['schemas']['SwaggerOperationResult'];
                    export type LiveEventNotificationDeliveryType = components['schemas']['LiveEventNotificationDeliveryType'];
                    export type NotificationDeliveryTypeViewModel = components['schemas']['NotificationDeliveryTypeViewModel'];
                    export type LiveEventNotificationViewModel = components['schemas']['LiveEventNotificationViewModel'];
                    export type LiveEventNotificationViewModelMaybe = components['schemas']['LiveEventNotificationViewModelMaybe'];
                    export type LiveEventSubscriptionViewModel = components['schemas']['LiveEventSubscriptionViewModel'];
                    export type LiveEventSubscriberViewModel = components['schemas']['LiveEventSubscriberViewModel'];
                    export type LiveEventSubscriberOverviewViewModel = components['schemas']['LiveEventSubscriberOverviewViewModel'];
                    export type AddLiveEventNotificationPostModel = components['schemas']['AddLiveEventNotificationPostModel'];
                    export type LiveEventStatus = components['schemas']['LiveEventStatus'];
                    export type ProductLiveEventModel = components['schemas']['ProductLiveEventModel'];
                    export type LiveEventInfo = components['schemas']['LiveEventInfo'];
                    export type DateTimeTranslations = components['schemas']['DateTimeTranslations'];
                    export type BambuserViewModel = components['schemas']['BambuserViewModel'];
                    

        export function CustomActionTypeactionDiscriminator<T>(
    model: components['schemas']['CustomActionType']['action'],
    funcCertificationCustomAction: (m: components['schemas']['CertificationCustomAction']) => T,
    funcJavascriptHookCustomAction: (m: components['schemas']['JavascriptHookCustomAction']) => T,
    funcLiveEventCustomAction: (m: components['schemas']['LiveEventCustomAction']) => T,
    funcSurveyCustomAction: (m: components['schemas']['SurveyCustomAction']) => T,
    funcVimeoCustomAction: (m: components['schemas']['VimeoCustomAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CertificationCustomAction': return funcCertificationCustomAction(model as components['schemas']['CertificationCustomAction']);
        case 'JavascriptHookCustomAction': return funcJavascriptHookCustomAction(model as components['schemas']['JavascriptHookCustomAction']);
        case 'LiveEventCustomAction': return funcLiveEventCustomAction(model as components['schemas']['LiveEventCustomAction']);
        case 'SurveyCustomAction': return funcSurveyCustomAction(model as components['schemas']['SurveyCustomAction']);
        case 'VimeoCustomAction': return funcVimeoCustomAction(model as components['schemas']['VimeoCustomAction']);

        default:
            return defaultFunc();
    }
}export function LinkActionTypeactionDiscriminator<T>(
    model: components['schemas']['LinkActionType']['action'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function LiveEventBlockViewModellinkActionDiscriminator<T>(
    model: components['schemas']['LiveEventBlockViewModel']['linkAction'],
    funcCustomActionType: (m: components['schemas']['CustomActionType']) => T,
    funcLinkActionType: (m: components['schemas']['LinkActionType']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'CustomActionType': return funcCustomActionType(model as components['schemas']['CustomActionType']);
        case 'LinkActionType': return funcLinkActionType(model as components['schemas']['LinkActionType']);

        default:
            return defaultFunc();
    }
}export function LiveEventSliderViewModelblocksDiscriminator<T>(
    model: components['schemas']['LiveEventSliderViewModel']['blocks'][0],
    funcLiveEventBlockViewModel: (m: components['schemas']['LiveEventBlockViewModel']) => T,
    funcSubscribeToNotificationsBlockViewModel: (m: components['schemas']['SubscribeToNotificationsBlockViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'LiveEventBlockViewModel': return funcLiveEventBlockViewModel(model as components['schemas']['LiveEventBlockViewModel']);
        case 'SubscribeToNotificationsBlockViewModel': return funcSubscribeToNotificationsBlockViewModel(model as components['schemas']['SubscribeToNotificationsBlockViewModel']);

        default:
            return defaultFunc();
    }
}export function LiveEventSliderViewModelMaybeblocksDiscriminator<T>(
    model: components['schemas']['LiveEventSliderViewModelMaybe']['blocks'][0],
    funcLiveEventBlockViewModel: (m: components['schemas']['LiveEventBlockViewModel']) => T,
    funcSubscribeToNotificationsBlockViewModel: (m: components['schemas']['SubscribeToNotificationsBlockViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'LiveEventBlockViewModel': return funcLiveEventBlockViewModel(model as components['schemas']['LiveEventBlockViewModel']);
        case 'SubscribeToNotificationsBlockViewModel': return funcSubscribeToNotificationsBlockViewModel(model as components['schemas']['SubscribeToNotificationsBlockViewModel']);

        default:
            return defaultFunc();
    }
}export function LiveEventFilteredResultViewModelresultsDiscriminator<T>(
    model: components['schemas']['LiveEventFilteredResultViewModel']['results'][0],
    funcLiveEventBlockViewModel: (m: components['schemas']['LiveEventBlockViewModel']) => T,
    funcSubscribeToNotificationsBlockViewModel: (m: components['schemas']['SubscribeToNotificationsBlockViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'LiveEventBlockViewModel': return funcLiveEventBlockViewModel(model as components['schemas']['LiveEventBlockViewModel']);
        case 'SubscribeToNotificationsBlockViewModel': return funcSubscribeToNotificationsBlockViewModel(model as components['schemas']['SubscribeToNotificationsBlockViewModel']);

        default:
            return defaultFunc();
    }
}export function LiveEventFilteredResultViewModelMayberesultsDiscriminator<T>(
    model: components['schemas']['LiveEventFilteredResultViewModelMaybe']['results'][0],
    funcLiveEventBlockViewModel: (m: components['schemas']['LiveEventBlockViewModel']) => T,
    funcSubscribeToNotificationsBlockViewModel: (m: components['schemas']['SubscribeToNotificationsBlockViewModel']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'LiveEventBlockViewModel': return funcLiveEventBlockViewModel(model as components['schemas']['LiveEventBlockViewModel']);
        case 'SubscribeToNotificationsBlockViewModel': return funcSubscribeToNotificationsBlockViewModel(model as components['schemas']['SubscribeToNotificationsBlockViewModel']);

        default:
            return defaultFunc();
    }
}export function LiveEventListViewModeldataLinkActionDiscriminator<T>(
    model: components['schemas']['LiveEventListViewModel']['dataLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function LiveEventListViewModelsubscriberModalLinkActionDiscriminator<T>(
    model: components['schemas']['LiveEventListViewModel']['subscriberModalLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function SwaggerOperationResultredirectLinkActionDiscriminator<T>(
    model: components['schemas']['SwaggerOperationResult']['redirectLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function LiveEventSubscriberOverviewViewModelpostLinkActionDiscriminator<T>(
    model: components['schemas']['LiveEventSubscriberOverviewViewModel']['postLinkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}export function BambuserViewModellinkActionDiscriminator<T>(
    model: components['schemas']['BambuserViewModel']['linkAction'],
    funcAdvisorLinkAction: (m: components['schemas']['AdvisorLinkAction']) => T,
    funcArticleSectionLinkAction: (m: components['schemas']['ArticleSectionLinkAction']) => T,
    funcArticleTagLinkAction: (m: components['schemas']['ArticleTagLinkAction']) => T,
    funcBrandClubLinkAction: (m: components['schemas']['BrandClubLinkAction']) => T,
    funcBrandLinkAction: (m: components['schemas']['BrandLinkAction']) => T,
    funcCampaignOfferLinkAction: (m: components['schemas']['CampaignOfferLinkAction']) => T,
    funcCmsMenuItemLinkAction: (m: components['schemas']['CmsMenuItemLinkAction']) => T,
    funcCmsPageLinkAction: (m: components['schemas']['CmsPageLinkAction']) => T,
    funcDiscountLinkAction: (m: components['schemas']['DiscountLinkAction']) => T,
    funcExternalLinkAction: (m: components['schemas']['ExternalLinkAction']) => T,
    funcFrontpageLinkAction: (m: components['schemas']['FrontpageLinkAction']) => T,
    funcPlainLinkAction: (m: components['schemas']['PlainLinkAction']) => T,
    funcProductGroupLinkAction: (m: components['schemas']['ProductGroupLinkAction']) => T,
    funcProductLinkAction: (m: components['schemas']['ProductLinkAction']) => T,
    funcSeriesLinkAction: (m: components['schemas']['SeriesLinkAction']) => T,
    funcStaticPageLinkAction: (m: components['schemas']['StaticPageLinkAction']) => T,
    funcStoreLinkAction: (m: components['schemas']['StoreLinkAction']) => T,
    defaultFunc: () => T
): T {
    switch (model?.$type) {
        case 'AdvisorLinkAction': return funcAdvisorLinkAction(model as components['schemas']['AdvisorLinkAction']);
        case 'ArticleSectionLinkAction': return funcArticleSectionLinkAction(model as components['schemas']['ArticleSectionLinkAction']);
        case 'ArticleTagLinkAction': return funcArticleTagLinkAction(model as components['schemas']['ArticleTagLinkAction']);
        case 'BrandClubLinkAction': return funcBrandClubLinkAction(model as components['schemas']['BrandClubLinkAction']);
        case 'BrandLinkAction': return funcBrandLinkAction(model as components['schemas']['BrandLinkAction']);
        case 'CampaignOfferLinkAction': return funcCampaignOfferLinkAction(model as components['schemas']['CampaignOfferLinkAction']);
        case 'CmsMenuItemLinkAction': return funcCmsMenuItemLinkAction(model as components['schemas']['CmsMenuItemLinkAction']);
        case 'CmsPageLinkAction': return funcCmsPageLinkAction(model as components['schemas']['CmsPageLinkAction']);
        case 'DiscountLinkAction': return funcDiscountLinkAction(model as components['schemas']['DiscountLinkAction']);
        case 'ExternalLinkAction': return funcExternalLinkAction(model as components['schemas']['ExternalLinkAction']);
        case 'FrontpageLinkAction': return funcFrontpageLinkAction(model as components['schemas']['FrontpageLinkAction']);
        case 'PlainLinkAction': return funcPlainLinkAction(model as components['schemas']['PlainLinkAction']);
        case 'ProductGroupLinkAction': return funcProductGroupLinkAction(model as components['schemas']['ProductGroupLinkAction']);
        case 'ProductLinkAction': return funcProductLinkAction(model as components['schemas']['ProductLinkAction']);
        case 'SeriesLinkAction': return funcSeriesLinkAction(model as components['schemas']['SeriesLinkAction']);
        case 'StaticPageLinkAction': return funcStaticPageLinkAction(model as components['schemas']['StaticPageLinkAction']);
        case 'StoreLinkAction': return funcStoreLinkAction(model as components['schemas']['StoreLinkAction']);

        default:
            return defaultFunc();
    }
}

        
        

        

        export type InspirationMatasLivePageViewModelSuccess = paths['/internal/Inspiration/InspirationMatasLivePageViewModel']['get']['responses']['200']['content']['text/plain'];

        export const InspirationMatasLivePageViewModelUrl = '/internal/Inspiration/InspirationMatasLivePageViewModel';

        
        export function InspirationMatasLivePageViewModel(): Promise<InspirationMatasLivePageViewModelSuccess> {
            return fetchJSON<InspirationMatasLivePageViewModelSuccess>(`/internal/Inspiration/InspirationMatasLivePageViewModel`)
        }
    


    
        

        export type UnsubscribeAllParams = paths['/internal/LiveEvent/UnsubscribeAll']['post']['parameters']['query'];

        export type UnsubscribeAllSuccess = paths['/internal/LiveEvent/UnsubscribeAll']['post']['responses']['200']['content']['text/plain'];

        export const UnsubscribeAllUrl = '/internal/LiveEvent/UnsubscribeAll';

        
        export function UnsubscribeAll(params: UnsubscribeAllParams): Promise<UnsubscribeAllSuccess> {
            return postQuery<UnsubscribeAllSuccess>(`/internal/LiveEvent/UnsubscribeAll${formatParams(params)}`)
        }
    


    
        

        export type GetEventSubscriberInfoParams = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['parameters']['query'];

        export type GetEventSubscriberInfoSuccess = paths['/internal/LiveEvent/GetEventSubscriberInfo']['get']['responses']['200']['content']['text/plain'];

        export const GetEventSubscriberInfoUrl = '/internal/LiveEvent/GetEventSubscriberInfo';

        
        export function GetEventSubscriberInfo(params: GetEventSubscriberInfoParams): Promise<GetEventSubscriberInfoSuccess> {
            return fetchJSON<GetEventSubscriberInfoSuccess>(`/internal/LiveEvent/GetEventSubscriberInfo${formatParams(params)}`)
        }
    


    
        export type AddEventNotificationRequestBody = paths['/internal/LiveEvent/AddEventNotification']['post']['requestBody']['content']['application/json'];

        

        

        export const AddEventNotificationUrl = '/internal/LiveEvent/AddEventNotification';

        
        export function AddEventNotification(requestBody: AddEventNotificationRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/LiveEvent/AddEventNotification`, requestBody)
        }
    


    
        

        export type GetLiveEventStatusModelParams = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['parameters']['query'];

        export type GetLiveEventStatusModelSuccess = paths['/internal/LiveEvent/GetLiveEventStatusModel']['get']['responses']['200']['content']['text/plain'];

        export const GetLiveEventStatusModelUrl = '/internal/LiveEvent/GetLiveEventStatusModel';

        
        export function GetLiveEventStatusModel(params: GetLiveEventStatusModelParams): Promise<GetLiveEventStatusModelSuccess> {
            return fetchJSON<GetLiveEventStatusModelSuccess>(`/internal/LiveEvent/GetLiveEventStatusModel${formatParams(params)}`)
        }
    


    
        

        

        export type LiveEventListViewModelSuccess = paths['/internal/LiveEvent/LiveEventListViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventListViewModelUrl = '/internal/LiveEvent/LiveEventListViewModel';

        
        export function LiveEventListViewModel(): Promise<LiveEventListViewModelSuccess> {
            return fetchJSON<LiveEventListViewModelSuccess>(`/internal/LiveEvent/LiveEventListViewModel`)
        }
    


    
        

        

        export type LiveEventFilteredResultViewModelSuccess = paths['/internal/LiveEvent/LiveEventFilteredResultViewModel']['get']['responses']['200']['content']['text/plain'];

        export const LiveEventFilteredResultViewModelUrl = '/internal/LiveEvent/LiveEventFilteredResultViewModel';

        
        export function LiveEventFilteredResultViewModel(): Promise<LiveEventFilteredResultViewModelSuccess> {
            return fetchJSON<LiveEventFilteredResultViewModelSuccess>(`/internal/LiveEvent/LiveEventFilteredResultViewModel`)
        }
    


    
        

        

        export type BambuserViewModelSuccess = paths['/internal/ModelOnly/BambuserViewModel']['get']['responses']['200']['content']['text/plain'];

        export const BambuserViewModelUrl = '/internal/ModelOnly/BambuserViewModel';

        
        export function BambuserViewModel(): Promise<BambuserViewModelSuccess> {
            return fetchJSON<BambuserViewModelSuccess>(`/internal/ModelOnly/BambuserViewModel`)
        }
    


    
    