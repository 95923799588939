import WithIcon from 'components/hoc/withIcon/WithIcon';
import React, { HTMLAttributes } from 'react';
import Button from './Button';
import IButtonWithIcon, { WithIconProps } from './shared/types/buttonWithIcon';

const ButtonWithIcon = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButtonWithIcon>(
    ({ icon, iconProperties, children, ...restProps }, ref) => {
        const hasChildren = children && React.Children.count(children);

        const buttonContent = icon ? (
            <WithIcon icon={icon} spacing={!hasChildren ? '0px' : iconProperties?.spacing} {...iconProperties}>
                {children}
            </WithIcon>
        ) : (
            children
        );

        return (
            <Button ref={ref} {...restProps}>
                {buttonContent}
            </Button>
        );
    }
);

export const withIcon = <T extends HTMLElement, P>(Component: React.ComponentType<P>) =>
    React.forwardRef<T, P & WithIconProps & HTMLAttributes<T>>((props, ref) => {
        const { icon, iconProperties, children, ...restProps } = props;
        const hasChildren = children && React.Children.count(children);

        const buttonContent = icon ? (
            <WithIcon icon={icon} spacing={!hasChildren ? '0px' : iconProperties?.spacing} {...iconProperties}>
                {children}
            </WithIcon>
        ) : (
            children
        );

        return (
            <Component ref={ref} {...(restProps as P)}>
                {buttonContent}
            </Component>
        );
    });

export default ButtonWithIcon;
