import { useQuery } from '@tanstack/react-query';
import type { SubscriptionInfoViewModel } from 'autogen/swagger/checkout';
import { AddedAs, AsSubscription } from 'autogen/translation-keys/trans-website-basket-subscription';
import InlineButton from 'components/generic/InlineButton';
import QuickView, { useQuickView } from 'components/quickView';
import { fetchHTML } from 'modules/helpers/fetch';
import ReactLoadingSpinner from 'modules/loadingSpinner';
import { formatReact } from 'modules/helpers/strings';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FontSize12, FontWeightSemiBold } from 'autogen/design-tokens/tokens';
import { Typography } from 'components/generic/typography';
import useGA4Tracking from 'modules/tracking/GA4';

const StyledInlineButton = styled(InlineButton)`
    font-size: ${FontSize12};
    font-weight: ${FontWeightSemiBold};
`;

export default function SubscriptionInformation({
    model: { infoLinkAction },
    productId
}: {
    model: SubscriptionInfoViewModel;
    productId: number;
}) {
    const { t } = useTranslation();
    const quickViewId = `SubscriptionInformationQuickView-${productId}`;
    const { toggle } = useQuickView(quickViewId);
    const { refetch, data, isLoading } = useQuery(['subscriptionInformation'], () => fetchHTML(infoLinkAction.url), {
        enabled: false
    });
    const GA4Tracking = useGA4Tracking();

    return (
        <>
            <QuickView quickViewId={quickViewId}>
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
                {!data || isLoading ? <ReactLoadingSpinner /> : <div dangerouslySetInnerHTML={{ __html: data }} />}
            </QuickView>
            <Typography.Body as="span" themeTypes="smallSemiBold">
                {formatReact(t(AddedAs))(
                    <StyledInlineButton
                        type="button"
                        onClick={() => {
                            refetch();
                            toggle(true);
                            GA4Tracking({
                                eventName: 'read_more',
                                category: 'ecommerce',
                                eventParams: [{ param_name: 'read_more_type', param_value: 'subscription_terms' }]
                            });
                        }}
                    >
                        {t(AsSubscription)}
                    </StyledInlineButton>
                )}
            </Typography.Body>
        </>
    );
}
