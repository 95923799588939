import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Translations from 'modules/translations';
import breakpoint from 'modules/breakpoint';
import { SvgSprite } from 'modules/svgSprite';
import MainContext from 'components/QuickSearch/components/context';
import { GA4Tracking } from 'modules/tracking/GA4';
import { layoutEnum, trackingActionEnum } from '../../enums';
import LinkAction from 'components/shared/Routing/components/LinkAction';

const List = styled.ul`
    padding: 0 0;
    margin: 0;
`;
const ListItem = styled.li`
    list-style: none;
    position: relative;
`;
const linkStyling = css`
    padding: 10px 20px;
    display: block;

    &:hover {
        text-decoration: none;
        background-color: #ececec;
    }
`;
const linkArrowStyling = css`
    &:after {
        content: '';
        position: absolute;
        display: block;
        transform: translateX(50%) rotate(45deg);
        right: -1px;
        top: 0;
        bottom: 0;
        width: ${39 / Math.sqrt(2)}px;
        height: ${39 / Math.sqrt(2)}px;
        background: #ececec;
        margin: auto;
        border-top: 1px solid #b8b8b8;
        border-right: 1px solid #b8b8b8;
        @media (min-width: ${breakpoint.sizes.xxxl}px) {
            width: ${44 / Math.sqrt(2)}px;
            height: ${44 / Math.sqrt(2)}px;
        }
    }
`;
const Link = styled(LinkAction)`
    ${linkStyling}
    ${(props) => props.layout !== layoutEnum.row && 'font-weight: 700;'}
    ${(props) => props.isActive && 'background-color: #ececec;'}
    position: relative;
    ${(props) => props.isHandheld && 'border-bottom: 1px solid #ececec;'}
    display: flex;

    ${(props) =>
        props.isActive &&
        props.layout === layoutEnum.column &&
        css`
            ${linkArrowStyling}
        `}
    &:after {
        ${(props) => props.hasArrow === false && 'content: none;'}
    }
`;
const SubLink = styled(LinkAction)`
    ${linkStyling}
    ${(props) => props.isActive && 'background-color: #ececec;'}
    ${(props) => props.isHandheld && 'border-bottom: 1px solid #ececec;'}
    padding-left: 40px;
    text-transform: lowercase;
    position: relative;
    ${(props) =>
        props.isActive &&
        props.layout === layoutEnum.column &&
        css`
            ${linkArrowStyling}
        `}
    &:after {
        ${(props) => props.hasArrow === false && 'content: none;'}
    }
`;
const HighLighted = styled.span`
    color: #00b2e2;
    font-weight: 700;
`;
const IconContainer = styled.label`
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    pointer-events: all;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    height: 39px;

    @media (min-width: ${breakpoint.sizes.xxxl}px) {
        height: 44px;
    }
`;
const StyledSvgSprite = styled(SvgSprite)`
    width: 1em;
    height: 1em;
`;

export default (props) => {
    const { items, trackingAction: listTrackingAction, searchTerm } = props;

    const mainContext = useContext(MainContext);
    const { layout, inputValue, isHandheld, onAutoFill, onItemHover, onItemLeave } = mainContext;

    const regex = new RegExp(/\s/g); // capture whitespaces

    const highlightSubstring = (string, substring) => {
        const stringToGoIntoRegexPattern = substring.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const regexPattern = new RegExp(`(^|)('${stringToGoIntoRegexPattern}')(|$)`, 'ig');
        const formattedString = string.replace(regexPattern, '$1<span class="text-weight-normal">$2</span>$3');
        return formattedString;
    };

    const formatText = (string) => {
        let formattedString = string;

        if (layout !== layoutEnum.row) {
            formattedString = highlightSubstring(string, inputValue);
        }

        return formattedString;
    };

    const trackingAction =
        listTrackingAction ||
        (layout === layoutEnum.row ? trackingActionEnum.popularSearchSuggestion : trackingActionEnum.querySuggestion);

    function getEventName(type) {
        switch (type) {
            case 'latestSearchSuggestion':
                return 'latest_search_suggestion';
            case 'popularSearchSuggestion':
                return 'popular_search_suggestion';
            case 'querySuggestion':
                return 'query_suggestion';
            default:
                break;
        }

        return undefined;
    }

    return (
        <List>
            {items.map((obj, index) => {
                const { title, linkAction, scopedQueries, isActive, hasArrow } = obj;
                const pathname = title.replace(regex, '-');

                return (
                    <ListItem key={pathname}>
                        <Link
                            className={
                                trackingAction === trackingActionEnum.nextQuery
                                    ? `tracking_quicksearch_${trackingAction} js-trackTextAsLabel`
                                    : `tracking_quicksearch_${trackingAction}_${pathname}`
                            }
                            action={linkAction}
                            layout={layout}
                            isActive={isActive}
                            hasArrow={hasArrow}
                            isHandheld={isHandheld}
                            onMouseMove={() => {
                                if (!isActive) {
                                    onItemHover(obj);
                                }
                            }}
                            onMouseLeave={(event) => onItemLeave({ x: event.pageX, y: event.pageY })}
                            onClick={() =>
                                GA4Tracking({
                                    eventName: getEventName(trackingAction),
                                    context: 'search',
                                    category: 'ecommerce',
                                    eventParams: [
                                        { param_name: 'search_type', param_value: 'quick_search' },
                                        { param_name: 'search_term', param_value: searchTerm || 'none' },
                                        { param_name: 'suggested_term', param_value: title }
                                    ]
                                })
                            }
                        >
                            <span dangerouslySetInnerHTML={{ __html: formatText(title) }} />
                        </Link>

                        <IconContainer
                            htmlFor="js-quicksearch-field"
                            onClick={() => {
                                onAutoFill(obj);
                            }}
                        >
                            <StyledSvgSprite sprite="ArrowAutoFill" />
                        </IconContainer>

                        {index === 0 &&
                            layout !== layoutEnum.row &&
                            scopedQueries &&
                            scopedQueries.length > 0 &&
                            scopedQueries.map((item, scopedQueriesIndex) => {
                                const { title, linkAction, isActive, productGroupName } = item;

                                const subpathname = title.replace(regex, '-');

                                return (
                                    <SubLink
                                        isActive={isActive}
                                        hasArrow={hasArrow}
                                        isHandheld={isHandheld}
                                        action={linkAction}
                                        key={scopedQueriesIndex}
                                        layout={layout}
                                        className={`tracking_quicksearch_${trackingAction}SubItem_${subpathname}-${productGroupName.toLowerCase()}`}
                                        onMouseMove={() => {
                                            if (!isActive) {
                                                onItemHover(item);
                                            }
                                        }}
                                        onMouseLeave={(event) => onItemLeave({ x: event.pageX, y: event.pageY })}
                                    >
                                        {Translations.Website.SearchDialog.ScopedProductGroupNamePrefix}
                                        <HighLighted>{productGroupName}</HighLighted>
                                    </SubLink>
                                );
                            })}
                    </ListItem>
                );
            })}
        </List>
    );
};
