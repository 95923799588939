
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './consent';

        export type AcceptedTermType = components['schemas']['AcceptedTermType'];
                    export type AcceptedTermResult = components['schemas']['AcceptedTermResult'];
                    

        

        
        

        export type AcceptTermsParams = paths['/internal/Consent/AcceptTerms']['post']['parameters']['query'];

        export type AcceptTermsSuccess = paths['/internal/Consent/AcceptTerms']['post']['responses']['200']['content']['text/plain'];

        export const AcceptTermsUrl = '/internal/Consent/AcceptTerms';

        
        export function AcceptTerms(params: AcceptTermsParams): Promise<AcceptTermsSuccess> {
            return postQuery<AcceptTermsSuccess>(`/internal/Consent/AcceptTerms${formatParams(params)}`)
        }
    


    
    