export type CustomContentProps = {
    content: any;
};

export default function CustomContent(props: CustomContentProps) {
    const { content } = props;

    const formatedContent = content.replace(/<\\\/script>/g, '</script>');

    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: formatedContent }} />;
}
