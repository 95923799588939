
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from '@tanstack/react-query';
        import {_LoginInfo,_LoginInfoSuccess,_LoginInfoParams} from './index'

        
        
    export const _LoginInfoQueryKey = 'login__LoginInfo';

    export function use_LoginInfoQuery<TError = unknown>(params: _LoginInfoParams, options?: Omit<UseQueryOptions<_LoginInfoSuccess, TError, _LoginInfoSuccess, [string, _LoginInfoParams,]>, 'queryKey' | 'queryFn' | 'initialData'> & { initialData?: _LoginInfoSuccess | (() => _LoginInfoSuccess);},  baseQueryKey = _LoginInfoQueryKey) {
            return useQuery([baseQueryKey, params,],
            () => _LoginInfo(params), options);
        }
  
    
    