import Spinner from 'modules/shared/circleSpinner';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import ButtonWithStatus from './ButtonWithStatus';
import IButtonWithSpinner, { WithSpinnerProps } from './shared/types/buttonWithSpinner';
import { WithAsyncStatusProps } from './shared/types/buttonWithStatus';

const Pending = styled.div`
    position: relative;
`;

const ChildrenWrapper = styled.span<any>`
    visibility: hidden;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ButtonWithSpinner = React.forwardRef<HTMLAnchorElement | HTMLButtonElement, IButtonWithSpinner>(
    ({ spinner = <StyledSpinner size={16} />, children, ...restProps }, ref) => (
        <ButtonWithStatus
            ref={ref}
            onIdle={children}
            onPending={
                <Pending>
                    {spinner}
                    <ChildrenWrapper>{children}</ChildrenWrapper>
                </Pending>
            }
            {...restProps}
        />
    )
);

export const withSpinner = <T extends HTMLElement, P extends WithAsyncStatusProps>(Component: React.ComponentType<P>) =>
    React.forwardRef<T, P & WithSpinnerProps & HTMLAttributes<T>>((props, ref) => {
        const { spinner = <StyledSpinner size={16} />, children, ...restProps } = props;

        return (
            <Component
                ref={ref}
                onIdle={children}
                onPending={
                    <Pending>
                        {spinner}
                        <ChildrenWrapper>{children}</ChildrenWrapper>
                    </Pending>
                }
                {...(restProps as P)}
            />
        );
    });

export default ButtonWithSpinner;
