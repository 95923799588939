
        /* eslint-disable camelcase */
        /* eslint-disable import/prefer-default-export */
        /* eslint-disable @typescript-eslint/no-unused-vars */
        import { fetchJSON, fetchHTML, postFormData, formatParams, postJSON, postQuery, putQuery, deleteQuery } from 'modules/helpers/fetch';
        import {components, paths} from './tracking';

        export type AdformImpressionTrackingViewModel = components['schemas']['AdformImpressionTrackingViewModel'];
                    export type TrackingEventType = components['schemas']['TrackingEventType'];
                    export type EcommercePromotionImpression = components['schemas']['EcommercePromotionImpression'];
                    export type EnhancedEcommerceTrackingEventOfEcommercePromotionImpression = components['schemas']['EnhancedEcommerceTrackingEventOfEcommercePromotionImpression'];
                    

        

        
        export type TrackAdformClickRequestBody = paths['/internal/Tracking/TrackAdformClick']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackAdformClickUrl = '/internal/Tracking/TrackAdformClick';

        
        export function TrackAdformClick(requestBody: TrackAdformClickRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/Tracking/TrackAdformClick`, requestBody)
        }
    


    
        export type TrackAdformImpressionsRequestBody = paths['/internal/Tracking/TrackAdformImpressions']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackAdformImpressionsUrl = '/internal/Tracking/TrackAdformImpressions';

        
        export function TrackAdformImpressions(requestBody: TrackAdformImpressionsRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/Tracking/TrackAdformImpressions`, requestBody)
        }
    


    
        export type TrackEventRequestBody = paths['/internal/Tracking/TrackEvent']['post']['requestBody']['content']['multipart/form-data'];

        

        

        export const TrackEventUrl = '/internal/Tracking/TrackEvent';

        
        export function TrackEvent(requestBody: TrackEventRequestBody): Promise<unknown> {
            return postFormData<unknown>(`/internal/Tracking/TrackEvent`, requestBody)
        }
    


    
        export type TrackEnhancedEcommercePromotionImpressionEventRequestBody = paths['/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent']['post']['requestBody']['content']['application/json'];

        

        

        export const TrackEnhancedEcommercePromotionImpressionEventUrl = '/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent';

        
        export function TrackEnhancedEcommercePromotionImpressionEvent(requestBody: TrackEnhancedEcommercePromotionImpressionEventRequestBody): Promise<unknown> {
            return postJSON<unknown>(`/internal/Tracking/TrackEnhancedEcommercePromotionImpressionEvent`, requestBody)
        }
    


    
    