import LinkAction, { LinkActionProps } from 'components/shared/Routing/components/LinkAction/LinkAction';
import { InlineButtonProps } from './shared/types/inlineButton';
import { withAsyncStatus } from 'components/Button/ButtonWithStatus';
import { withIcon, withSpinner } from 'modules/react-button';
import React from 'react';
import styled, { css } from 'styled-components';
import { inlineButtonFontWeight, fontWeightStyling } from './shared/inlineButtonFontWeight';
import { inlineButtonSize, sizeStyling } from './shared/inlineButtonSize';
import { inlineButtonVariantKeys, variantStyling } from './shared/inlineButtonVariants';
import { defaultStyling } from './shared/styling';

export const StyledInlineButton = styled(LinkAction)<{
    $fontWeight: inlineButtonFontWeight;
    variant?: inlineButtonVariantKeys;
    size?: inlineButtonSize;
    $disabled?: boolean;
}>`
    ${defaultStyling}

    ${({ variant }) => variant && variantStyling};

    ${({ size }) =>
        size
            ? sizeStyling
            : css`
                  font-size: inherit;
              `};

    ${fontWeightStyling}
`;

export type LinkActionButtonProps = LinkActionProps & InlineButtonProps<HTMLAnchorElement>;

const InlineLinkActionButton = React.forwardRef<HTMLAnchorElement, LinkActionButtonProps>(
    (
        {
            action,
            variant = 'dark',
            size,
            fontWeight = 'regular',
            disabled,
            onClick,
            onClickDisabled,
            tabIndex,
            children,
            ...restProps
        },
        ref
    ) => (
        <StyledInlineButton
            action={action}
            ref={ref}
            variant={variant}
            size={size}
            $fontWeight={fontWeight}
            $disabled={disabled}
            tabIndex={disabled ? -1 : tabIndex}
            onClick={(event) => {
                if (disabled) {
                    event.preventDefault(); // prevents anchor navigation
                    if (onClickDisabled) onClickDisabled(event);
                    return; // prevents usual onClick events
                }
                if (onClick) onClick(event);
            }}
            {...restProps}
        >
            {children}
        </StyledInlineButton>
    )
);

export default Object.assign(InlineLinkActionButton, {
    WithIcon: withIcon(InlineLinkActionButton),
    WithAsyncStatus: withAsyncStatus(InlineLinkActionButton),
    WithSpinner: withSpinner(withAsyncStatus(InlineLinkActionButton))
});
