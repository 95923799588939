import { MenuItemViewModel } from 'autogen/swagger/menu';
import AnchorWrapper from 'components/List/components/AnchorWrapper';
import ButtonWrapper from 'components/List/components/ButtonWrapper';
import { rem } from 'modules/helpers/style';
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import MenuItemStyleRenderer, { IMenuItemStyleRenderer } from '../MenuItemStyleRenderer';
import { LinkActionProps } from 'components/shared/Routing/components/LinkAction/LinkAction';

const MenuItemIcon = styled.img`
    width: ${rem(24)};
    height: ${rem(24)};
`;

export interface IDefaultMenuItem extends IMenuItemStyleRenderer {
    iconUrl: MenuItemViewModel['iconUrl'];
}

/**
 * Abstraction on top of MenuItemStyleRenderer (identical to ListItem). Always occupies the beforeSlot with an icon that's always a part of MenuItemViewModel
 */
export function DefaultMenuItem({
    iconUrl,
    styling,
    children,
    beforeSlot = iconUrl ? <MenuItemIcon src={iconUrl} /> : undefined, // before slot is set to render a menuIcon by default if iconUrl exists
    ...restProps
}: IDefaultMenuItem) {
    return (
        <MenuItemStyleRenderer {...restProps} beforeSlot={beforeSlot} styling={styling}>
            {children}
        </MenuItemStyleRenderer>
    );
}

export interface IAnchorMenuItem extends Omit<IDefaultMenuItem, 'wrapperSlot'> {
    anchorProps: LinkActionProps;
}

/**
 * Abstraction on top of DefaultMenuItem (identical to ListItem). Always occupies the wrapperSlot with an Anchor Tag.
 */
export function AnchorMenuItem({ anchorProps, children, ...restProps }: IAnchorMenuItem) {
    return (
        <DefaultMenuItem
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<AnchorWrapper {...anchorProps} />}
        >
            {children}
        </DefaultMenuItem>
    );
}

export interface IButtonMenuItem extends Omit<IDefaultMenuItem, 'wrapperSlot'> {
    buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

/**
 * Abstraction on top of DefaultMenuItem (identical to ListItem). Always occupies the wrapperSlot with an Button Tag.
 */
export function ButtonMenuItem({ buttonProps, children, ...restProps }: IButtonMenuItem) {
    return (
        <DefaultMenuItem
            {...restProps}
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            wrapperSlot={<ButtonWrapper {...buttonProps} />}
        >
            {children}
        </DefaultMenuItem>
    );
}

export default Object.assign(DefaultMenuItem, {
    Button: ButtonMenuItem,
    Anchor: AnchorMenuItem
});
