import {
    LinkActionTypeactionDiscriminator,
    type PageActionModelSuccess,
    getPageActionModelDiscriminator
} from 'autogen/swagger/page';

/**
 * This is only used temporarily til components have been made compatible with pageAction
 * @param pageAction
 * @returns
 */
export default function getUrlFromPageAction(pageAction?: PageActionModelSuccess) {
    if (!pageAction) return null;

    return getPageActionModelDiscriminator(
        pageAction,
        () => null,
        (linkAction) =>
            LinkActionTypeactionDiscriminator(
                linkAction.action,
                (advisorPageAction) => advisorPageAction.url,
                (articleSectionPageAction) => articleSectionPageAction.url,
                (articleTagPageAction) => articleTagPageAction.url,
                (brandClubPageAction) => brandClubPageAction.url,
                (brandPageAction) => brandPageAction.url,
                (campaignOfferPageAction) => campaignOfferPageAction.url,
                (cmsMenuItemPageAction) => cmsMenuItemPageAction.url,
                (cmsPagePageAction) => cmsPagePageAction.url,
                (discountPageAction) => discountPageAction.url,
                (externalLinkAction) => externalLinkAction.url,
                (frontpagePageAction) => frontpagePageAction.url,
                (plainLinkAction) => plainLinkAction.url,
                (productGroupPageAction) => productGroupPageAction.url,
                (productPageAction) => productPageAction.url,
                (seriesPageAction) => seriesPageAction.url,
                (staticPagePageAction) => staticPagePageAction.url,
                (storePageAction) => storePageAction.url,
                () => null
            ),
        () => null
    );
}
